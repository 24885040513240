<template>
  <div id="app" :env="env" class="theme">
    <nav-menu v-if="isAuthenticated"></nav-menu>
    <div v-if="waiting">
      <loading-spinner />
    </div>
    <router-view v-show="!waiting"></router-view>
  </div>
</template>

<script>
import NavMenu from "./components/nav-menu";
import LoadingSpinner from "./components/loading-spinner.vue";
import { eventEmitter, events } from "./services/eventEmitter";
import { getEnvironment } from "./services/env";

export default {
  name: "App",
  components: {
    "nav-menu": NavMenu,
    "loading-spinner": LoadingSpinner,
  },
  data() {
    return {
      isAuthenticated: false,
      waiting: true,
      env: getEnvironment(),
    };
  },
  computed: {},
  created() {
    console.log("app-root created()");
  },
  mounted() {
    this.waiting = true;
    eventEmitter.on(events.authenticated, () => {
      console.log("eventEmitter.on(events.authenticated)");
      this.isAuthenticated = true;
    });
    eventEmitter.on(events.authenticationCompleted, () => {
      console.log("eventEmitter.on(events.authenticationCompleted)");
      this.waiting = false;
    });
  },
  methods: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
