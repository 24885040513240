<template>
  <div class="container-fluid container-paddings">
    <div class="row align-items-end">
      <div class="col">
        {{ session.clinicalCase == 0 ? "Non-" : "" }}Clinical observation
        <h3>
          <b>Case ID: {{ session.caseId }}</b>
        </h3>
      </div>

      <div v-if="isDownloading" class="col-auto d-flex align-items-center">
        <i class="spinner" style="height: 35px; width: 35px"></i>
        <div v-if="downloadProgressPercentage" style="padding-left: 10px">
          Progress: {{ downloadProgressPercentage }}%
        </div>
      </div>

      <div class="col-auto">
        <div class="btn-group" role="group">
          <button
            id="download"
            type="button"
            class="btn btn-primary dropdown-toggle d-flex align-items-center"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <laerdal-icon
              class="icon-margin"
              icon="Download"
              height="20"
              width="20"
              fill="white"
            ></laerdal-icon>
            Download
          </button>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="download"
          >
            <li>
              <button
                type="button"
                class="dropdown-item d-flex justify-content-between"
                @click="downloadCsv()"
              >
                Liveborn observation
              </button>
            </li>

            <li>
              <button
                :disabled="!session.neoBeatData"
                type="button"
                class="dropdown-item d-flex justify-content-between"
                @click="downloadNeoBeatData()"
              >
                NeoBeat raw data
              </button>
            </li>

            <li>
              <button
                :disabled="!session.moyoData"
                type="button"
                class="dropdown-item d-flex justify-content-between"
                @click="downloadMoyoData()"
              >
                Moyo raw data
              </button>
            </li>

            <li v-if="canDownloadVideos">
              <button
                :disabled="session.videoStatus !== 'Available'"
                type="button"
                class="dropdown-item d-flex"
                @click="downloadVideo()"
              >
                <laerdal-icon
                  class="list-icon"
                  height="18"
                  width="18"
                  :icon="authenticatedWithTwoFactor ? 'LockedOff' : 'LockedOn'"
                ></laerdal-icon>
                <span style="padding-left: 5px">Video</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-auto d-flex">
        <laerdal-icon
          class="icon-margin"
          icon="Calendar"
          height="18"
          width="18"
        ></laerdal-icon>
        {{ formatDate(session.start) }}
      </div>

      <div class="col-auto d-flex">
        <laerdal-icon
          class="icon-margin"
          icon="Time"
          height="18"
          width="18"
        ></laerdal-icon>
        {{ formatTime(session.start) }}
      </div>

      <div class="col-auto">
        <b class="icon-margin">Outcome:</b>
        {{ formatOutcome(session.outcome) }}
      </div>
    </div>

    <div v-if="session.comments" class="row align-items-center">
      <div class="col">
        <div class="card">
          <div class="card-body d-flex">
            <div class="icon-margin"><b>Comment:</b></div>
            <div>
              {{ session.comments }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col">
        <div id="accordion" class="accordion">
          <!-- More information -->
          <div class="accordion-item">
            <h2 id="headingMoreInfo" class="accordion-header">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseMoreInfo"
                aria-expanded="false"
                aria-controls="collapseMoreInfo"
              >
                <laerdal-icon
                  class="icon-margin"
                  icon="Information"
                ></laerdal-icon>
                More information
              </button>
            </h2>

            <div
              id="collapseMoreInfo"
              class="accordion-collapse collapse"
              aria-labelledby="headingMoreInfo"
            >
              <div class="accordion-body">
                <more-information-content :object-list="moreInformation" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- The graphs -->
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div id="activity-graph" style="width: 99%"></div>
            <div id="heart-rate-graph" style="width: 99%"></div>
            <div id="signal-graph" style="width: 99%"></div>
            <div id="energy-graph" style="width: 99%"></div>
            <div id="orientation-graph" style="width: 99%"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Raw data -->
    <div class="row justify-content-end">
      <div class="col-auto">
        <button class="btn btn-secondary" @click="showRawData = !showRawData">
          <span v-if="showRawData" class="d-flex">
            <laerdal-icon
              class="icon-margin"
              icon="VisibleOff"
              fill="white"
            ></laerdal-icon>
            Hide detailed data
          </span>

          <span v-else class="d-flex">
            <laerdal-icon
              class="icon-margin"
              icon="VisibleOn"
              fill="white"
            ></laerdal-icon>
            Show detailed data
          </span>
        </button>
      </div>
    </div>

    <div v-if="showRawData" class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Observation-data</h4>
            <h6 class="card-subtitle mb-2 text-muted">
              All details about manually registered observations
            </h6>
            <table class="table table-bordered table-hover liveborn-table">
              <thead>
                <tr>
                  <!--<th></th>-->
                  <th>Start</th>
                  <th>Stop</th>
                  <th>Start ms</th>
                  <th>Stop ms</th>
                  <th>Activity</th>
                  <th>Deleted</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(activity, index) in session.activities"
                  :key="index"
                  :style="{
                    backgroundColor: activity.color,
                  }"
                >
                  <!--<td><img style="height: 30px;" :alt="activity.activityType" :src="'/dist/assets/images/activities/' + activity.activityType + '.png'"/></td>-->
                  <td class="text-end">
                    {{ formatMilliSeconds(activity.start) }}
                  </td>
                  <td class="text-end">
                    {{ formatMilliSeconds(activity.stop) }}
                  </td>
                  <td class="text-end">{{ activity.start }}</td>
                  <td class="text-end">{{ activity.stop }}</td>
                  <td>{{ activity.activityType }}</td>
                  <td>{{ activity.deleted }}</td>
                  <td>{{ activity.note }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showRawData" class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">NeoBeat-data</h4>
            <h6 class="card-subtitle mb-2 text-muted">
              All registered NeoBeat data
            </h6>
            <h6 class="card-subtitle mb-2 text-muted">
              NeoBeat offset: {{ session.neoBeatOffset }} ms
              <small>
                (Number of milliseconds from observation start to the start of
                the NeoBeat episode)
              </small>
            </h6>
            <h6 class="card-subtitle mb-2 text-muted">
              NeoBeat series:
              {{ session.heartRateSeries.length }}
            </h6>
            <h6 class="card-subtitle mb-2 text-muted">
              Estimated NeoBeat episode start time:
              {{ formatDateSeconds(session.neoBeatEpisodeStart) }}
              <br />
            </h6>
            <table class="table table-bordered table-hover liveborn-table">
              <thead>
                <tr>
                  <th class="text-end">Adjusted Time</th>
                  <th class="text-end">Raw timestamp</th>
                  <th class="text-end">Heart-rate</th>
                  <th class="text-end">Signal quality</th>
                  <th class="text-end">Temperature</th>
                  <th class="text-end">Accelerate energy</th>
                  <th class="text-end">Accelerate X</th>
                  <th class="text-end">Accelerate Y</th>
                  <th class="text-end">Accelerate Z</th>
                  <th class="text-end">Light</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(hr, index) in session.heartRates" :key="index">
                  <td class="text-end">
                    {{
                      formatMilliSecondsWithMs(
                        hr.timeStamp * 1000 + session.neoBeatOffset
                      )
                    }}
                  </td>
                  <td class="text-end">
                    {{
                      hr.originalTimeStamp ? hr.originalTimeStamp : hr.timeStamp
                    }}
                  </td>
                  <td class="text-end">{{ hr.heartRate }}</td>
                  <td class="text-end">{{ hr.signalQuality }}</td>
                  <td class="text-end">{{ hr.temperature }}</td>
                  <td class="text-end">{{ hr.accelerateEnergy }}</td>
                  <td class="text-end">{{ hr.accelerateX }}</td>
                  <td class="text-end">{{ hr.accelerateY }}</td>
                  <td class="text-end">{{ hr.accelerateZ }}</td>
                  <td class="text-end">{{ hr.light }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LaerdalIcon from "../components/laerdal-icon.vue";
import MoreInformationContent from "../components/more-information-content.vue";
import { scaleLinear } from "d3-scale";
import { range } from "d3-array";
import { axisBottom } from "d3-axis";
import apiService from "../services/api.service";
import authService from "../services/auth.service";
import heartRateGraph from "../graphs/neobeat/heart-rate-graph";
import activityGraph from "../graphs/activity-graph";
import energyGraph from "../graphs/neobeat/energy-graph";
import orientationGraph from "../graphs/neobeat/orientation-graph";
import signalGraph from "../graphs/neobeat/signal-graph";
import formatterService from "../services/formatter.service";
import common from "../graphs/graph-common";
import { downloadBlobs, blobTypes } from "../services/blob.service";
import { eventEmitter, events } from "../services/eventEmitter";
import {Tooltip } from "bootstrap";

export default {
  components: {
    "laerdal-icon": LaerdalIcon,
    MoreInformationContent,
  },
  data() {
    return {
      session: {},
      debrief: {},
      moreInformation: [],
      separator: ";",
      showDebrief: false,
      showRawData: false,
      isFetching: false,
      isDownloading: false,
      canDownloadVideos: false,
      downloadProgressPercentage: null,
      authenticatedWithTwoFactor: false,
    };
  },
  created() {
    this.apiService = apiService;
  },
  mounted() {
    this.getData();
    const user = authService.getHttpUser();
    this.authenticatedWithTwoFactor = user.authenticatedWithTwoFactor;
    eventEmitter.on(events.downloadProgressPercentage, (percentage) => {
      this.downloadProgressPercentage = percentage;
    });

    // Enable Bootstrap tooltips
    document
      .querySelectorAll('[data-bs-toggle="tooltip"]')
      .forEach((tooltip) => {
        new Tooltip(tooltip);
      });
  },
  methods: {
    formatDate(value) {
      return formatterService.formatDate(value);
    },
    formatDateSeconds(value) {
      return formatterService.formatDateSeconds(value);
    },
    formatTime(value) {
      return formatterService.formatTime(value);
    },
    formatClinicalSession(value) {
      return formatterService.formatClinicalSession(value);
    },
    formatSeconds(value) {
      return formatterService.formatSeconds(value);
    },
    formatOutcome(value) {
      return formatterService.formatOutcome(value);
    },
    formatMilliSeconds(value) {
      return formatterService.formatMilliSeconds(value);
    },
    formatMilliSecondsWithMs(value) {
      return formatterService.formatMilliSecondsWithMs(value);
    },
    goBack() {
      window.close();
      // event.preventDefault();
      // return false;
    },
    downloadCsv() {
      this.isDownloading = true;
      this.apiService.downloadSessionCsv(this.$route.query.id).then(
        () => {
          this.isDownloading = false;
        },
        () => {
          this.isDownloading = false;
        }
      );
    },
    downloadNeoBeatData() {
      this.isDownloading = true;

      this.apiService
        .getBlobReferences(
          `neobeat/getAllForLivebornEpisode?observationId=${this.session.id}`,
          this.$toast
        )
        .then(
          (response) => {
            const { caseId, sasTokens } = response.data;
            const fileName = `NEOBEAT_Episodes_${caseId}.zip`;
            downloadBlobs(blobTypes.NEOBEAT, fileName, sasTokens).then(
              () => {
                this.isDownloading = false;
              },
              (err) => {
                console.log(err);
                this.isDownloading = false;
              }
            );
          },
          () => {
            this.isDownloading = false;
          }
        );
    },
    downloadMoyoData() {
      this.isDownloading = true;

      this.apiService
        .getBlobReferences(
          `moyo/getAllForLivebornEpisode?livebornObservationId=${this.session.id}`,
          this.$toast
        )
        .then(
          (response) => {
            const { caseId, sasTokens } = response.data;
            const fileName = `MOYO_Episodes_${caseId}.zip`;
            downloadBlobs(blobTypes.MOYO, fileName, sasTokens).then(
              () => {
                this.isDownloading = false;
              },
              (err) => {
                console.log(err);
                this.isDownloading = false;
              }
            );
          },
          () => {
            this.isDownloading = false;
          }
        );
    },
    downloadVideo() {
      this.isDownloading = true;

      this.apiService.downloadVideo(this.session.id, this.$toast).then(
        () => {
          this.isDownloading = false;
          this.downloadProgressPercentage = null;
        },
        () => {
          this.isDownloading = false;
          this.downloadProgressPercentage = null;
        }
      );
    },
    async setDownloadVideosOnSite(siteId) {
      const { data, error } = await this.apiService.getDataFromEndpoint(
        "userpermission/myuserpermissions"
      );

      if (data) {
        this.canDownloadVideos = data.some(
          (permission) =>
            permission.canDownloadVideos && permission.siteId === siteId
        );
      } else {
        console.log(error);
        this.$toast.error("Error when fetching user permissions", {
          position: "bottom",
        });
      }
    },
    async getData() {
      if (!this.$route.query.id) {
        window.location.href = "/";
        return;
      }
      this.isFetching = true;
      const { data, error } = await this.apiService.getDataFromEndpoint(
        `observation/${this.$route.query.id}`
      );

      if (data) {
        // Hack to mark first menu as active
        // If you know how to do this in Vue, feel free to do it the proper way...
        // document.getElementById("topMenu").children[0].children[0].classList.add("router-link-exact-active");

        document.title = data.caseId;
        this.session = data;
        this.debrief = data.debrief;
        this.moreInformation = this.sortMoreInformationData(data);

        // Make an adjusted timestamp on each heart rate, so it matches up with the session timeline
        // Also split elements into series around "holes" in the data
        // Define a "hole" as a gap of more than 1500 ms
        let previousTimeStamp = 0;
        const series = [];
        let currentSeries = [];
        common.yAxisWidth = 100;
        for (let i = 0; i < data.heartRates.length; i++) {
          const hr = data.heartRates[i];
          // First find the timestamp adjusted to fit onto the Liveborn observation time axis
          hr.adjustedTimeStamp = hr.timeStamp * 1000 + data.neoBeatOffset;
          // Was there a gap, bigger than 1500 milliseconds in the data?
          if (
            hr.adjustedTimeStamp - previousTimeStamp > 2500 &&
            currentSeries.length > 0
          ) {
            // If so, "close" the current series and start a new one
            currentSeries.push(
              getBoundaryDataPoint(
                previousTimeStamp,
                currentSeries[currentSeries.length - 1].signalQuality
              )
            );
            series.push(currentSeries);
            // Reset current series
            currentSeries = [];
            // Insert a zero-point at the beginning
            currentSeries.push(
              getBoundaryDataPoint(hr.adjustedTimeStamp, hr.signalQuality)
            );
          }
          // Finally add "current" heart rate
          currentSeries.push(hr);
          // Update previous value:
          previousTimeStamp = hr.adjustedTimeStamp;
        }
        // The loop above does not add last series, since there is no detected "gap" at the end
        if (currentSeries.length > 0) {
          // Add the "zero" data point for the first series
          const previousDataPoint = currentSeries[currentSeries.length - 1];
          currentSeries.push(
            getBoundaryDataPoint(
              previousDataPoint.adjustedTimeStamp,
              previousDataPoint.signalQuality
            )
          );
          series.push(currentSeries);
        }
        // Also, the loop does not insert a leading "zero" data point for the first series
        if (series.length > 0) {
          const firstDataPoint = series[0][0];
          series[0].unshift(
            getBoundaryDataPoint(
              firstDataPoint.adjustedTimeStamp,
              firstDataPoint.signalQuality
            )
          );
        }
        data.heartRateSeries = series;
        // Now draw the graphs
        redraw(data);
        // If user resizes windows, redraw again
        window.addEventListener("resize", () => redraw(data));
        await this.setDownloadVideosOnSite(data.siteId);
        this.isFetching = false;
      } else {
        console.log(error);
      }
    },
    sortMoreInformationData(data) {
      return [
        {
          providers: {
            label: "Number of providers",
            value: data.providers > 0 ? data.providers : "N/A",
          },
          observer: {
            label: "Observer",
            value: data.observerId,
          },
          uploaded: {
            label: "Uploaded",
            value: this.formatDateSeconds(data.uploaded),
          },
          uploadedBy: {
            label: "Uploaded By",
            value: data.uploadedByUserName,
          },
          livebornAppVersion: {
            label: "Liveborn app version",
            value: data.appVersion,
          },
        },
        {
          neoBeatSerialNumber: {
            label: "NeoBeat S/N (Alias)",
            value: `${data.neoBeatSerialNumber || "N/A"} (${
              data.neoBeatAlias || "N/A"
            })`,
          },
          neoBeatFirmwareVersion: {
            label: "NeoBeat FW",
            value: data.neoBeatFirmwareVersion || "N/A",
          },
          numberOfNeoBeatEpisodes: {
            label: "NeoBeat Raw Data",
            value: data.numberOfNeoBeatEpisodes || "N/A",
          },
          numberOfMoyoEpisodes: {
            label: "Moyo Raw Data",
            value: data.numberOfMoyoEpisodes || "N/A",
          },
          numberOfNeo100Episodes: {
            label: "Neo100 Raw Data",
            value: data.numberOfNeo100Episodes || "N/A",
          },
          numberOfLivebornStationVideos: {
            label: "Liveborn Station videos",
            value: data.numberOfLivebornStationVideos || "N/A",
          },
        },
      ];
    },
  },
};

const redraw = (session) => {
  // Use any graph div to find width
  const graphDiv = document.getElementById("activity-graph");
  const width = graphDiv.clientWidth;

  const maxValue = session.duration * 1000;
  const scaleX = scaleLinear()
    .domain([0, maxValue])
    .range([common.yAxisWidth, width]);
  // X tick lines defined by visible seconds interval:
  //   * More than 5 minutes: every 60 seconds
  //   * 1,5 min to 5 min: Every 30 seconds
  //   * Less than 1,5 minutes: Every 10 seconds
  const tickIntervalSeconds =
    session.duration > 5 * 60 ? 60 : session.duration > 90 ? 30 : 10;
  const xAxisTickValues = range(0, maxValue, tickIntervalSeconds * 1000);
  const xAxis = axisBottom(scaleX)
    .tickValues(xAxisTickValues)
    .tickFormat(formatterService.formatMilliSeconds);

  activityGraph.drawActivityGraph(
    "activity-graph",
    session,
    width,
    scaleX,
    xAxis,
    xAxisTickValues
  );
  heartRateGraph.drawHeartRateGraph(
    "heart-rate-graph",
    session,
    width,
    scaleX,
    xAxis,
    xAxisTickValues
  );
  energyGraph.drawEnergyGraph(
    "energy-graph",
    session,
    width,
    scaleX,
    xAxis,
    xAxisTickValues
  );
  orientationGraph.drawEnergyGraph(
    "orientation-graph",
    session,
    width,
    scaleX,
    xAxis,
    xAxisTickValues
  );
  signalGraph.drawSignalGraph(
    "signal-graph",
    session,
    width,
    scaleX,
    xAxis,
    xAxisTickValues
  );
};

const getBoundaryDataPoint = (timeStamp, signalQuality) => {
  return {
    accelerateEnergy: 0,
    accelerateX: 0,
    accelerateY: 0,
    accelerateZ: 1000,
    heartRate: 0,
    light: 0,
    signalQuality,
    // signalQualityOK: false,
    temperature: 0,
    adjustedTimeStamp: timeStamp,
  };
};
</script>

<style scoped>
small {
  font-size: 80%;
  font-weight: 400;
}

.container-paddings {
  padding-top: var(--bs-gutter-y, 0.75rem);
  padding-bottom: var(--bs-gutter-y, 0.75rem);
}
</style>
