//
// Common for most graphs
//
const padding = 3;
let yAxisWidth;
const xAxisHeight = 30;
const marginTop = 10;
const marginLeft = 15;
let xAxis;
let scaleX;
let width;
let xAxisTickValues;
let maxXValue;

export default {
  padding,
  xAxisHeight,
  marginTop,
  marginLeft,
  // Use globals for all x-axis related vars, used by all charts (same timeline...)
  xAxis,
  scaleX,
  width,
  xAxisTickValues,
  yAxisWidth,
  maxXValue,
  drawYLines(svg, yPoints, yScale, width) {
    svg
      .append("g")
      .attr("class", "grid")
      .selectAll("line")
      .data(yPoints)
      .enter()
      .append("line")
      .attr("x2", width)
      .attr("y2", (d) => yScale(d))
      .attr("y1", (d) => yScale(d))
      .attr("transform", "translate(" + yAxisWidth + "," + marginTop + ")");
  },
};
