export const DEFAULT_PAGESIZE = 100;
export const BAD_REQUEST = 400;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const SERVER_ERROR = 500;

// DATA TYPES
const LIVEBORN = "observation";
const MOYO = "moyo";
const NEOBEAT = "neobeat";
const NEO_NATALIE = "neonatalie";
const SCENARIO_TRAINING = "scenariotraining";

export const DATA_TYPES = {
  LIVEBORN,
  MOYO,
  NEOBEAT,
  NEO_NATALIE,
  SCENARIO_TRAINING,
};

export const API_ERRORS = {
  NOT_FOUND,
  FORBIDDEN,
  SERVER_ERROR,
};
