import { BlobServiceClient } from "@azure/storage-blob";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { getBlobConfigurationForEnvironment } from "./env";

export const blobTypes = {
  MOYO: "moyo/",
  NEOBEAT: "neobeat/",
  NEONATALIE: "neonatalie/",
};

const blobConfiguration = getBlobConfigurationForEnvironment();
console.log("blobConfiguration: ", blobConfiguration);

const getBlobClient = (sasToken) => {
  return new BlobServiceClient(
    `https://${blobConfiguration.accountName}.blob.core.windows.net${sasToken}`
  );
};

const getBlobName = (blobType, blobId) => {
  return `${blobType}${blobId}.zip`;
};

const getBlob = async (blobId, blobType, containerClient) => {
  const blobName = getBlobName(blobType, blobId);

  const blobClient = containerClient.getBlobClient(blobName);
  const { metadata } = await blobClient.getProperties();
  const fileName = `${metadata.filename}.zip`;
  const downloadBlockBlobResponse = await blobClient.download();
  return {
    binaries: downloadBlockBlobResponse,
    fileName,
  };
};

const downloadBlobToArchive = async (
  archive,
  blobIdAndToken,
  blobType,
  arrayIndex
) => {
  console.log(`arrayindex ${arrayIndex} start`);

  const blobClient = getBlobClient(blobIdAndToken.value);
  const containerClient = blobClient.getContainerClient(
    blobConfiguration.containerName
  );
  const { binaries, fileName } = await getBlob(
    blobIdAndToken.key,
    blobType,
    containerClient
  );
  archive.file(fileName, binaries.blobBody);
  console.log(`arrayindex ${arrayIndex} blob archived`);
};

export const downloadBlob = async (blobType, blobId, sasToken) => {
  console.log("blobType: ", blobType);
  console.log("blobId: ", blobId);
  console.log("sasToken: ", sasToken);
  const blobClient = getBlobClient(sasToken);
  const containerClient = blobClient.getContainerClient(
    blobConfiguration.containerName
  );
  try {
    const { binaries, fileName } = await getBlob(
      blobId,
      blobType,
      containerClient
    );
    saveAs(await binaries.blobBody, fileName);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(
      new Error("raw data for episode is currently not available")
    );
  }
};

export const downloadBlobs = async (blobType, fileName, sasTokens) => {
  const zip = new JSZip();
  await Promise.all(
    sasTokens.map(async (blobIdAndToken, index) =>
      downloadBlobToArchive(zip, blobIdAndToken, blobType, index)
    )
  ).then(
    async () => {
      console.log("all blobs downloaded and added to archive");
      await zip.generateAsync({ type: "blob" }).then(
        (content) => {
          saveAs(content, fileName);
          return Promise.resolve();
        },
        (err) => {
          console.log(err);
          return Promise.reject(
            new Error("Could not save raw data for episodes to file")
          );
        }
      );
    },
    (reason) => {
      console.log("reason: ", reason);
      return Promise.reject(
        new Error("raw data for episodes is currently not available")
      );
    }
  );
};
