<template>
  <div :class="{ 'selected-item': isActive }">
    <router-link
      :to="linkPath"
      class="nav-link router-link-exact-active router-link-active my-auto"
      :class="{ active: isActive }"
      >{{ linkTitle }}</router-link
    >
  </div>
</template>

<script>
export default {
  name: "NavbarRouterLink",
  props: ["linkPath", "linkStyle", "linkTitle"],
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    $route(to) {
      this.isActive = to.fullPath === this.linkPath;
    },
  },
  mounted() {
    this.isActive = this.$router.currentRoute.value.path === this.linkPath;
  },
};
</script>

<style scoped>
div {
  height: 100%;
  border-bottom-style: solid;
  border-bottom-color: white;
  display: flex;
  align-items: center;
}

div:hover {
  background-color: var(--primary-color-opacity);
  border-color: var(--primary-color-opacity);
}

.selected-item {
  background-color: var(--primary-color-opacity);
  border-color: var(--primary-color) !important;
}
</style>
