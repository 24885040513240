<template>
  <div id="laerdalIcon" v-html="htmlIcon"></div>
</template>

<script>
import { SystemIcons, ContentIcons } from "@laerdal/figma-svg";

export default {
  name: "LaerdalIcon",
  props: {
    icon: {
      default: "",
    },
    width: {
      default: "24",
    },
    height: {
      default: "24",
    },
    fill: {
      default: "#000000",
    },
  },
  computed: {
    cssFill() {
      return this.getFillColor(this.fill);
    },
    htmlIcon() {
      return this.getHtmlIcon(this.icon);
    },
  },
  methods: {
    getHtmlIcon(icon) {
      let svgHtml;

      if (SystemIcons[icon]) {
        svgHtml = SystemIcons[icon];
      } else if (ContentIcons[icon]) {
        svgHtml = ContentIcons[icon];
      } else {
        return svgHtml;
      }

      return svgHtml
        .replaceAll(/width=".[^"]*"/g, `width="${this.width}"`)
        .replaceAll(/height=".[^"]*"/g, `height="${this.height}"`)
        .replaceAll(/fill=".[^"]*"/g, `fill="${this.cssFill}"`);
    },
    getFillColor(fill) {
      return (
        getComputedStyle(document.getElementsByClassName("theme")[0])
          .getPropertyValue(fill)
          .trim() || fill
      );
    },
  },
};
</script>

<style scoped></style>
