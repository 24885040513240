<template>
  <div
    style="overflow: hidden;
           position: absolute;
           top: 0;
           left: 0;
           width: 100%;
           height: 100%;
           display: flex;
           justify-content: center; /*centers items on the line (the x-axis by default)*/
           align-items: center; "
  >
    <svg
      style="opacity: 0; animation-name: fadeInOut, spin; animation-duration: 2s; animation-iteration-count: infinite"
      version="1"
      viewBox="0 0 2000 752"
      width="30%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M645.5 1.6c-43.3 5.9-83 22.8-118 50.4-24.4 19.2-50.9 52.5-64.3 80.8-3.5 7.3-3.6 7.4-10.7 10.6-19.8 9.1-45.2 14.4-87.5 18.3-16 1.4-38.9 1.7-170.5 2.2l-152 .6-5.9 2.2c-17.6 6.7-29.8 19.6-35 37.2C.2 208.8 0 225.7 0 376.5c0 150.8.2 167.7 1.6 172.6 5.2 17.3 17.1 30.2 34 36.7l6.9 2.6 143 .7c86.7.4 150.1 1.1 161 1.8 54.3 3.4 84.2 8.8 107.7 19.6 5.5 2.5 5.8 2.9 11.4 13.3 12.2 22.8 25 40.6 41.6 57.5 38.7 39.2 89 63.8 143 69.6 6.6.8 117.1 1.1 348.5 1.1 359.7 0 347.3.1 369.5-4.5 60.8-12.6 115.3-49.6 149.5-101.5 5.8-8.7 16.3-27.5 16.3-29.1 0-1.4 17.1-9.3 27.8-12.9 15.1-4.9 36.7-8.7 66.2-11.5 25.2-2.4 85.5-3.4 208.5-3.5 72.6 0 114.3-.4 118.5-1.1 15.2-2.4 31.6-14.1 38.7-27.4 6.8-13 6.3.7 6.3-184 0-150.8-.2-167.7-1.6-172.6-5.2-17.3-17.1-30.1-34.4-36.9l-6.5-2.5-154-.7c-84.7-.3-155.6-.9-157.5-1.2-1.9-.3-9.6-1-17-1.6-37.1-3.1-62.1-8.7-84.8-19.1l-8.7-4-3.3-6.7c-13.2-27.6-38-58.6-62.7-78.5C1434 24.1 1393.9 7 1349 1.4c-16.6-2-688.2-1.9-703.5.2zm695 60.4c56.9 6.5 108.7 42.4 135.4 94 10.7 20.6 17 42.6 19 66.5 1.4 15.9 1.5 290.6.1 306.5-2.1 24.3-8.5 46.5-19.2 67-27.1 52.2-77 86.6-135.8 93.9-5.8.8-114.6 1.1-342.2 1.1-363.6 0-342.8.3-365.1-5.5-62.1-16.1-110.9-65.2-126.6-127.3-5.2-20.3-5.1-17.7-5.1-182.2s-.1-161.9 5.1-182.2c17.7-70 77-122.5 148.9-131.7 9.7-1.3 674.4-1.3 685.5-.1z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner'
};
</script>

<style scoped></style>
