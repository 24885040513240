import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { generateW3CId } from "@microsoft/applicationinsights-core-js";

const injectKey = Symbol("appInsights");

/**
 * Setup page tracking using router.
 * @param appInsights ApplicationInsights instance.
 * @param options Application insights plugin options.
 */
function setupPageTracking(appInsights, options) {
  const appName = options.appName ? `[${options.appName}] ` : "";

  const pageName = (route) => `${appName}${route.name}`;

  options.router.beforeEach((route) => {
    const name = pageName(route);
    appInsights.context.telemetryTrace.traceID = generateW3CId();
    appInsights.context.telemetryTrace.name = route.name;
    appInsights.startTrackPage(name);
  });

  options.router.afterEach((route) => {
    const name = pageName(route);
    const url = location.protocol + "//" + location.host + route.fullPath;
    appInsights.stopTrackPage(name, url);
  });
}

/**
 * Setup page tracking if router option is defined.
 * @param appInsights ApplicationInsights instance.
 * @param options Application insights plugin options.
 */
function configurePageTrackingWithRouter(
  appInsights,
  options
) {
  options.router.isReady().then(() => setupPageTracking(appInsights, options));
}

export const AppInsightsPlugin = {
  install: (app, options) => {
    const appInsightsConfig = {
      config: {
        connectionString: options.connectionString,
      },
    };

    const appInsights = new ApplicationInsights(appInsightsConfig);
    // Inject AppInsights for later use
    app.config.globalProperties.$appInsights = appInsights;
    app.provide(injectKey, appInsights);
    appInsights.loadAppInsights();

    if (options.cloudRole) {
      appInsights.addTelemetryInitializer((envelope) => {
        envelope.tags ??= [];
        if (options.cloudRole) {
          envelope.tags["ai.cloud.role"] = options.cloudRole;
        }
      });
    }
    configurePageTrackingWithRouter(appInsights, options);

    if (options.onLoaded) {
      options.onLoaded(appInsights);
    }
  },
};

export default AppInsightsPlugin;