import _ from "lodash";
import { scaleLinear } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { selectAll } from "d3-selection";
import common from "../graph-common";

export default {
  drawHeartRateGraph(
    graphDivId,
    session,
    width,
    scaleX,
    xAxis,
    xAxisTickValues
  ) {
    const graphDiv = document.getElementById(graphDivId);
    graphDiv.innerHTML = "";
    const heartRateGraphHeight = 100 + common.xAxisHeight + common.marginTop;
    const getHRColor = (hr) => (hr.signalQuality === 2 ? "#4e99c9" : "#a3c2d5");

    // First make the heart rate graph(if there are heart rates != 0)
    const heartRates = _.filter(session.heartRates, (hr) => hr.heartRate > 0);
    // if (heartRates.length) {
    const heartRateSvg = selectAll(`#${graphDivId}`)
      .append("svg")
      .attr("transform", `translate(0,${common.marginTop})`)
      .attr("width", width)
      .attr("height", heartRateGraphHeight)
      .attr("class", "svg");
    // Make the y-scale and y-axis
    const heartRateScaleY = scaleLinear()
      .domain([250, 0])
      .range([0, heartRateGraphHeight - common.xAxisHeight - common.marginTop]);
    const heartRateYAxis = axisLeft(heartRateScaleY);

    // Draw the axes
    heartRateSvg
      .append("g")
      .attr(
        "transform",
        `translate(0,${heartRateGraphHeight - common.xAxisHeight})`
      )
      .call(xAxis);

    heartRateSvg
      .append("g")
      .attr("transform", `translate(${common.yAxisWidth},${common.marginTop})`)
      .call(heartRateYAxis.ticks(5));

    // draw vertical and horizontal grid lines
    heartRateSvg
      .append("g")
      .attr("class", "grid")
      .attr(
        "transform",
        `translate(0,${heartRateGraphHeight - common.xAxisHeight})`
      )
      .call(
        axisBottom(scaleX)
          .tickValues(xAxisTickValues)
          .tickSize(common.marginTop - heartRateGraphHeight)
          .tickFormat("")
      );

    // Draw a line for 50, 100, 150, 200 and 250
    heartRateSvg
      .append("g")
      .attr("class", "grid")
      .selectAll("line")
      .data([50, 100, 150, 200, 250])
      .enter()
      .append("line")
      .attr("x2", width)
      .attr("y2", (d) => heartRateScaleY(d))
      .attr("y1", (d) => heartRateScaleY(d))
      .attr("transform", `translate(${common.yAxisWidth},${common.marginTop})`);

    // Draw chart header
    const headerCenterY = heartRateGraphHeight / 2 - common.marginTop;
    heartRateSvg
      .append("text")
      .text("Heart rate [bpm]")
      .attr("text-anchor", "middle")
      .attr("x", common.marginLeft)
      .attr("y", headerCenterY)
      // Center and rotate 90 degrees
      .attr("transform", `rotate(270,${common.marginLeft},${headerCenterY})`);

    /*
            heartRateSvg.append("path")
                .datum(heartRates)
                .attr("fill", "none")
                .attr("stroke", getHRColor)
                .attr("stroke-width", 3)
                .attr("transform", "translate(0," + common.marginTop + ")")
                .attr("d", line()
                    .x(function(d) { return scaleX(d.adjustedTimeStamp) })
                    .y(function(d) { return heartRateScaleY(d.heartRate) })
                );
                */

    if (heartRates.length) {
      heartRateSvg
        .append("g")
        .selectAll("dot")
        .data(heartRates)
        .enter()
        .append("circle")
        .attr("transform", `translate(0,${common.marginTop})`)
        .attr("cx", function (d) {
          return scaleX(d.adjustedTimeStamp);
        })
        .attr("cy", function (d) {
          return heartRateScaleY(d.heartRate);
        })
        .attr("r", 5)
        .attr("fill", getHRColor);
    }
  },
  // }
};
