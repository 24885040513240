import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Toaster from "@meforma/vue-toaster";
import "./css/site.css";
import "./css/graph.css";
import "./css/theme.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "core-js/es/promise";
import "core-js/es/array";
import { AppInsightsPlugin } from './plugins/AppInsightsPlugin'

createApp(App)
    .use(router)
    .use(AppInsightsPlugin, {
        connectionString: window.appInsightsConnectionString,
        router: router,
        appName: "Liveborn App",
        cloudRole: window.appInsightsCloudInstance
    })
    .use(Toaster).mount("#app");
