import _ from "lodash";
import { axisBottom } from "d3-axis";
import { selectAll } from "d3-selection";
import common from "./graph-common";
import formatter from "../services/formatter.service";

const activityLines = [
  { name: "Breathing" },
  { name: "Skin to skin" },
  { name: "Dry / Stimulate" },
  { name: "Suction" },
  { name: "Ventilation" },
  { name: "Events" },
];

export default {
  drawActivityGraph(
    graphDivId,
    session,
    width,
    scaleX,
    xAxis,
    xAxisTickValues
  ) {
    const graphDiv = document.getElementById(graphDivId);
    // First clear old stuff
    graphDiv.innerHTML = "";
    const barHeight = 30;
    const lineHeight = barHeight + common.padding * 2;
    const activityGraphHeight =
      lineHeight * activityLines.length + common.xAxisHeight + common.marginTop;
    const nonDeletedActivities = _.filter(
      session.activities,
      (a) => !a.deleted
    );
    const commentedActivities = _.filter(nonDeletedActivities, (a) => !!a.note);

    // Add video start as activity
    if (session.timeToVideoStart) {
      nonDeletedActivities.push({
        start: session.timeToVideoStart,
        stop: 0,
        lineNumber: 6,
        activityType: "VideoStart",
        deleted: false,
        note: "",
      });
    }

    // Next make the activity graph
    const svg = selectAll("#" + graphDivId)
      .append("svg")
      .attr("width", width)
      .attr("height", activityGraphHeight)
      .attr("class", "svg");

    // draw vertical and horizontal grid lines
    svg
      .append("g")
      .attr("class", "grid")
      .attr(
        "transform",
        "translate(0," + (activityGraphHeight - common.xAxisHeight) + ")"
      )
      .call(
        axisBottom(scaleX)
          .tickValues(xAxisTickValues)
          .tickSize(-activityGraphHeight)
          .tickFormat("")
      );

    svg
      .append("g")
      .attr("class", "grid")
      .selectAll("line")
      .data(activityLines)
      .enter()
      .append("line")
      .attr("x2", width)
      .attr(
        "transform",
        (d, ix) => "translate(0," + (ix * lineHeight + common.marginTop) + ")"
      );

    // Draw the boxes
    svg
      .append("g")
      .selectAll("rect")
      // Only select "timespan" activities(with a "stop")
      .data(_.filter(nonDeletedActivities, (a) => !!a.stop))
      .enter()
      .append("rect")
      .attr("x", (d) => scaleX(d.start))
      .attr("y", (d) => (d.lineNumber - 1) * lineHeight + common.padding)
      .attr("transform", "translate(0," + common.marginTop + ")")
      .attr("height", barHeight)
      .attr("width", (d) => scaleX(d.stop) - scaleX(d.start))
      .attr("fill", (d) => d.color);

    // Draw the bottom axis
    svg
      .append("g")
      .attr(
        "transform",
        "translate(0," + (activityGraphHeight - common.xAxisHeight) + ")"
      )
      .call(xAxis);

    // Draw the labels(y-axis labels)
    svg
      .append("g")
      .selectAll("text")
      .data(activityLines)
      .enter()
      .append("text")
      .attr(
        "transform",
        (d, ix) =>
          "translate(0," +
          ((ix + 1) * lineHeight - lineHeight / 2 + common.marginTop) +
          ")"
      )
      .attr("height", barHeight - 3)
      .attr("dominant-baseline", "central")
      .text((d) => d.name);

    // Add some icons to the bottom line
    const timestampActivities = _.filter(nonDeletedActivities, (a) => !a.stop);
    svg
      .append("g")
      .selectAll("image")
      .data(timestampActivities)
      .enter()
      .append("image")
      .attr("transform", "translate(0," + common.marginTop + ")")
      .attr("y", (d) => (d.lineNumber - 1) * lineHeight + common.padding)
      .attr("x", (d) => scaleX(d.start) - barHeight / 2)
      .attr("width", barHeight)
      .attr("length", barHeight)
      .attr("data-bs-toggle", "tooltip")
      .attr("data-bs-placement", "bottom")
      .attr("title", (d) => formatter.formatMilliSeconds(d.start))
      .attr("xlink:href", (d) =>
        require("@/assets/images/activities/" + d.activityType + ".png")
      );

    // Draw comment symbols(where applicable) with flyover displaying the actual note
    const commentImageScaleFactor = 0.7;
    svg
      .append("g")
      .selectAll("image")
      .data(commentedActivities)
      .enter()
      .append("image")
      .attr("transform", "translate(0," + common.marginTop + ")")
      .attr("y", (d) => {
        // if this is a start/stop(box) activity, center it vertically,
        // otherwise it should top align
        let top = (d.lineNumber - 1) * lineHeight;
        if (d.stop) {
          top +=
            common.padding + (barHeight * (1 - commentImageScaleFactor)) / 2;
        }
        return top;
      })
      .attr("x", (d) => {
        // if this is a start/stop(box) activity, center it horizontally,
        // otherwise offset it slightly(50%) right
        let xPositionUnscaled = d.start;
        if (d.stop) xPositionUnscaled += (d.stop - d.start) / 2;
        let scaledXPosition = scaleX(xPositionUnscaled);
        let leftAdjustment = (barHeight * commentImageScaleFactor) / 2;
        if (d.stop) leftAdjustment *= -1;
        scaledXPosition += leftAdjustment;
        return scaledXPosition;
      })
      .attr("width", barHeight * commentImageScaleFactor)
      .attr("length", barHeight * commentImageScaleFactor)
      .attr("xlink:href", () => require("@/assets/images/comments.png"))
      .append("title")
      .text((d) => d.note);
  },
};
