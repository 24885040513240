<template>
  <div class="row justify-content-around">
    <div v-for="(table, i) in objectList" :key="i" class="col">
      <table class="table">
        <tbody>
          <tr
            v-for="row in getRows(table)"
            :key="row.label"
            class="text-nowrap"
          >
            <th scope="row">{{ row.label }}</th>
            <td class="d-flex justify-content-end">{{ row.value }}</td>
            <td v-if="hasAnyRowsHelp(table)" style="width: 1%">
              <laerdal-icon
                v-if="row.help"
                icon="Help"
                height="16"
                width="16"
                data-bs-toggle="tooltip"
                data-bs-html="true"
                data-bs-placement="bottom"
                :title="row.help"
              ></laerdal-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import LaerdalIcon from "./laerdal-icon.vue";
import { Tooltip } from "bootstrap";

export default {
  name: "MoreInformationContent",
  components: {
    "laerdal-icon": LaerdalIcon,
  },
  props: ["objectList"],
  updated() {
    // Enable Bootstrap tooltips
    document
      .querySelectorAll('[data-bs-toggle="tooltip"]')
      .forEach((tooltip) => {
        new Tooltip(tooltip);
      });
  },
  methods: {
    getRows(table) {
      let rows = [];
      Object.getOwnPropertyNames(table).forEach((propertyName) => {
        rows.push(table[propertyName]);
      });

      return rows;
    },
    hasAnyRowsHelp(table) {
      return Object.getOwnPropertyNames(table).some(
        (propertyName) => table[propertyName].help
      );
    },
  },
};
</script>

<style>
.tooltip-inner {
  text-align: left !important;
}

.table {
  margin-bottom: 0px !important;
}

table > tbody > :last-child > * {
  border-bottom-width: 0px;
}
</style>
