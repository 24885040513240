import { line } from "d3-shape";
import { scaleLinear } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { selectAll } from "d3-selection";
import common from "../graph-common";

export default {
  drawEnergyGraph(graphDivId, session, width, scaleX, xAxis, xAxisTickValues) {
    let graphDiv = document.getElementById(graphDivId);
    graphDiv.innerHTML = "";
    let heartRateGraphHeight = 100 + common.xAxisHeight + common.marginTop;

    // Make orientation graph from NeoBeat data
    if (session.heartRateSeries.length) {
      let svg = selectAll("#" + graphDivId)
        .append("svg")
        .attr("transform", "translate(0," + common.marginTop + ")")
        .attr("width", width)
        .attr("height", heartRateGraphHeight)
        .attr("class", "svg");
      // Make the y-scale and y-axis
      let scaleY = scaleLinear()
        .domain([1, -1])
        .range([
          0,
          heartRateGraphHeight - common.xAxisHeight - common.marginTop,
        ]);
      let heartRateYAxis = axisLeft(scaleY);

      // Draw the axes
      svg
        .append("g")
        .attr(
          "transform",
          "translate(0," + (heartRateGraphHeight - common.xAxisHeight) + ")"
        )
        .call(xAxis);

      svg
        .append("g")
        .attr(
          "transform",
          "translate(" + common.yAxisWidth + "," + common.marginTop + ")"
        )
        .call(heartRateYAxis.ticks(5));

      // draw vertical and horizontal grid lines
      svg
        .append("g")
        .attr("class", "grid")
        .attr(
          "transform",
          "translate(0," + (heartRateGraphHeight - common.xAxisHeight) + ")"
        )
        .call(
          axisBottom(scaleX)
            .tickValues(xAxisTickValues)
            .tickSize(common.marginTop - heartRateGraphHeight)
            .tickFormat("")
        );

      // Draw horizontal lines
      svg
        .append("g")
        .attr("class", "grid")
        .selectAll("line")
        .data([-1, 0, 1])
        .enter()
        .append("line")
        .attr("x2", width)
        .attr("y2", (d) => scaleY(d))
        .attr("y1", (d) => scaleY(d))
        .attr(
          "transform",
          "translate(" + common.yAxisWidth + "," + common.marginTop + ")"
        );

      // Draw chart header
      let headerCenterY = heartRateGraphHeight / 2 - common.marginTop;
      svg
        .append("text")
        .text("Orientation [G]")
        .attr("text-anchor", "middle")
        .attr("x", common.marginLeft)
        .attr("y", headerCenterY)
        // Center and rotate 90 degrees
        .attr(
          "transform",
          "rotate(270," + common.marginLeft + "," + headerCenterY + ")"
        );

      let xFunction = (d) => scaleX(d.adjustedTimeStamp);

      for (let i = 0; i < session.heartRateSeries.length; i++) {
        drawArea(svg, session.heartRateSeries[i], "#45B97C", xFunction, (d) =>
          scaleY(d.accelerateX / 1000)
        );
        drawArea(svg, session.heartRateSeries[i], "#1870B8", xFunction, (d) =>
          scaleY(d.accelerateY / 1000)
        );
        drawArea(svg, session.heartRateSeries[i], "#6B6C6F", xFunction, (d) =>
          scaleY(d.accelerateZ / 1000)
        );
      }
    }
  },
};

function drawArea(svg, data, color, xFunction, yFunction) {
  svg
    .append("path")
    .datum(data)
    .attr("fill", color)
    .attr("opacity", ".6")
    .attr("stroke", "#000")
    .attr("stroke-width", 2)
    .attr("transform", "translate(0," + common.marginTop + ")")
    .attr("stroke-linejoin", "round")
    .attr(
      "d",
      line()
        //.curve(curveBasis)
        .x(xFunction)
        .y(yFunction)
    );
}
