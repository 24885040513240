import { createRouter, createWebHistory } from "vue-router";
import { eventEmitter, events } from "../services/eventEmitter";
import authService from "../services/auth.service";
import apiService from "../services/api.service";
import { routes } from "./routes";
import { createToaster } from "@meforma/vue-toaster";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const checkIfUserIsNeoNatalieOnly = (user) => {
  if (!user || user === null) return false;

  return (
    user.subscribesToNeoNatalie &&
    !user.subscribesToLiveborn &&
    !user.subscribesToMoyo &&
    !user.subscribesToNeoBeat
  );
};

const neoNatalieRedirectRoutes = [
  "/",
  "/observationDetails",
  "/neoBeatEpisodes",
  "/moyoEpisodes",
];

const handleAuthenticatedUser = (to, next, httpUser) => {
  if (
    neoNatalieRedirectRoutes.includes(to.path) &&
    checkIfUserIsNeoNatalieOnly(httpUser)
  ) {
    next("/neoNatalieSessions");
  } else {
    next();
  }
};

const handleJwtAuthenticatedUser = async (jwt, to, next, httpUser) => {
  eventEmitter.emit(events.fetchingUserPermissionStarted);
  apiService.setAuthToken(jwt);
  const { data, error } = await apiService.getDataFromEndpoint("user");
  eventEmitter.emit(events.fetchingUserPermissionEnded);

  if (data) {
    authService.sethttpUser(data);
    eventEmitter.emit(events.authenticated);
    eventEmitter.emit(events.authenticationCompleted);
    handleAuthenticatedUser(to, next, httpUser);
  } else {
    console.log("error: ", error);
    await authService.navigateToLogin(to.path, to.query);
  }
};

const handleUnauthenticatedUser = async (to, next, httpUser) => {
  const { jwt } = await authService.getUserAndToken();
  if (jwt === null) {
    await authService.navigateToLogin(to.path, to.query);
  } else {
    await handleJwtAuthenticatedUser(jwt, to, next, httpUser);
  }
};

router.beforeEach(async (to, from, next) => {
  createToaster().clear();
  const isAuthenticated = authService.isAuthenticated();
  const httpUser = authService.getHttpUser();

  if (!isAuthenticated) {
    // Redirect unauthorized user to base URL if they go to a 2FA page
    // directly rather through then through the navigation menu
    if (to.meta.require2fa) {
      to.path = "/";
    }
    await handleUnauthenticatedUser(to, next, httpUser);
  } else if (to.meta.require2fa && !httpUser.authenticatedWithTwoFactor) {
    await authService.handle2faLogin();
  } else {
    handleAuthenticatedUser(to, next, httpUser);
  }
});

export default router;
