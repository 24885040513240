import EventEmitter from "eventemitter3";

export const events = {
  loggedIn: "loggedIn",
  loggedOut: "loggedOut",
  authenticated: "authenticated",
  jwtTimeOut: "jwtTimeOut",
  authenticating: "authenticating",
  authenticationCompleted: "aythenticationCompleted",
  loginMounted: "loginMounted",
  fetchingUserPermissionStarted: "fetchingUserPermissionStarted",
  fetchingUserPermissionEnded: "fetchingUserPermissionEnded",
  downloadProgressPercentage: "downloadProgressPercentage",
};

export const eventEmitter = new EventEmitter();
