import { line, curveStepAfter } from "d3-shape";
import { scaleLinear } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { selectAll } from "d3-selection";
import common from "../graph-common";

export default {
  drawSignalGraph(graphDivId, session, width, scaleX, xAxis, xAxisTickValues) {
    let graphDiv = document.getElementById(graphDivId);
    graphDiv.innerHTML = "";
    let heartRateGraphHeight = 100 + common.xAxisHeight + common.marginTop;

    // Make energy graph from NeoBeat data
    if (session.heartRateSeries.length) {
      let svg = selectAll("#" + graphDivId)
        .append("svg")
        .attr("transform", "translate(0," + common.marginTop + ")")
        .attr("width", width)
        .attr("height", heartRateGraphHeight)
        .attr("class", "svg");
      // Make the y-scale and y-axis
      let scaleY = scaleLinear()
        .domain([2, -2.1])
        .range([
          0,
          heartRateGraphHeight - common.xAxisHeight - common.marginTop,
        ]);
      let heartRateYAxis = axisLeft(scaleY).tickFormat((d) => {
        switch (d) {
          case -2:
            return "Off";
          case -1:
            return "Activity";
          case 0:
            return "Poor";
          case 1:
            return "Unclear";
          case 2:
            return "Good";
          default:
            return "Unknown";
        }
      });

      // Draw the axes
      svg
        .append("g")
        .attr(
          "transform",
          "translate(0," + (heartRateGraphHeight - common.xAxisHeight) + ")"
        )
        .call(xAxis);

      svg
        .append("g")
        .attr(
          "transform",
          "translate(" + common.yAxisWidth + "," + common.marginTop + ")"
        )
        .call(heartRateYAxis.ticks(5));

      // draw vertical and horizontal grid lines
      svg
        .append("g")
        .attr("class", "grid")
        .attr(
          "transform",
          "translate(0," + (heartRateGraphHeight - common.xAxisHeight) + ")"
        )
        .call(
          axisBottom(scaleX)
            .tickValues(xAxisTickValues)
            .tickSize(common.marginTop - heartRateGraphHeight)
            .tickFormat("")
        );

      // Draw horizontal lines
      svg
        .append("g")
        .selectAll("line")
        .data([2, 1, 0, -1, -2.1])
        .enter()
        .append("line")
        .attr("stroke", "lightgrey")
        .attr("x2", width)
        .attr("y2", (d) => scaleY(d))
        .attr("y1", (d) => scaleY(d))
        .attr(
          "transform",
          "translate(" + common.yAxisWidth + "," + common.marginTop + ")"
        );

      // Draw chart header
      let headerCenterY = heartRateGraphHeight / 2 - common.marginTop;
      svg
        .append("text")
        .text("Signal quality")
        .attr("text-anchor", "middle")
        .attr("x", common.marginLeft)
        .attr("y", headerCenterY)
        // Center and rotate 90 degrees
        .attr(
          "transform",
          "rotate(270," + common.marginLeft + "," + headerCenterY + ")"
        );

      for (let i = 0; i < session.heartRateSeries.length; i++) {
        svg
          .append("path")
          .datum(session.heartRateSeries[i])
          .attr("fill", "transparent")
          .attr("opacity", ".6")
          .attr("stroke", "darkblue")
          .attr("stroke-width", 2)
          .attr("transform", "translate(0," + common.marginTop + ")")
          .attr("stroke-linejoin", "round")
          .attr(
            "d",
            line()
              .curve(curveStepAfter)
              .x(function (d) {
                return scaleX(d.adjustedTimeStamp);
              })
              .y(function (d) {
                return scaleY(d.signalQuality);
              })
          );
      }
    }
  },
};
