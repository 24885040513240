// import moment from 'vue-moment'
import moment from "moment";

// https://stackoverflow.com/questions/2998784/how-to-output-integers-with-leading-zeros-in-javascript
const pad = (num, size) => {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};

const formatDateSeconds = (value) => {
  if (!value) return "";
  return moment(value).format("YYYY\u2011MM\u2011DD HH:mm:ss");
};

const formatDate = (value) => {
  if (!value) return "";
  return moment(value).format("YYYY\u2011MM\u2011DD");
};

const formatTime = (value) => {
  if (!value) return "";
  return moment(value).format("HH:mm:ss");
};

const formatSecondsAsHours = (value) => {
  if (!value) return "";
  const absoluteValue = Math.abs(value);
  let minutes = Math.floor(absoluteValue / 60);
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  const seconds = absoluteValue % 60;
  const time = `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
  return value < 0 ? `-${time}` : time;
};

const formatSeconds = (value) => {
  if (!value) return "";
  const absoluteValue = Math.abs(value);
  let minutes = Math.floor(absoluteValue / 60);
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  const seconds = absoluteValue % 60;
  let time = `${pad(minutes, 2)}:${pad(seconds, 2)}`;
  if (hours > 0) time = `${pad(hours, 2)}:${time}`;
  return value < 0 ? `-${time}` : time;
};

const formatMilliSeconds = (value) => {
  if (!value) return "";
  return formatSeconds(Math.floor(value / 1000));
};

const formatMilliSecondsWithMs = (value) => {
  const seconds = Math.floor(value / 1000);
  const ms = value - seconds * 1000;
  return `${formatSeconds(seconds)}.${pad(ms, 3)}`;
};

const formatOutcome = (value) => {
  switch (parseInt(value, 10)) {
    case 0:
      return "Dead";
    case 2:
      return "FSB";
    case 3:
      return "MSB";
    default:
      return "Alive";
  }
};

const formatClinicalSession = (value) => {
  if (parseInt(value, 10) === 0) return "No";
  if (parseInt(value, 10) === 1) return "Yes";
  return "";
};

const formatBooleanAsYesNo = (value) => {
  return value ? "Yes" : "No";
};

const formatPercentOrNA = (value) => {
  if (value === 0) return "N/A";
  return `${value}%`;
};

const formatNumberOrEmptyIfZero = (value) => {
  if (value === 0) return "";
  return value;
};

const formatLearners = (value) => {
  if (!value) return "N/A";
  return value.map((element) => element.value).join(", ");
};

const formatPatientCase = (value) => {
  if (!value) return "";
  switch (value) {
    case 1:
      return "Patient case 1";
    case 2:
      return "Patient case 2";
    case 3:
      return "Patient case 3";
    case 4:
      return "Patient case 4";
    case 6:
      return "Ventilation practice";
    default:
      return "";
  }
};

const formatScenarioCase = (value) => {
  if (!value) return "";
  switch (value) {
    case 2:
      return "Preparation for birth";
    case 3:
      return "Routine care";
    case 4:
      return "GM: Stimulation";
    case 5:
      return "GM: Ventilation";
    case 6:
      return "Improve and continue ventilation";
    case 7:
      return "Advanced care";
    default:
      return "";
  }
};

const formatVideoStatus = (value) => {
  if (!value) return "";

  // Note that "Not Available" status returns an empty string
  switch (value) {
    case "NotUploaded":
      return "Not Uploaded";
    case "Deleted":
    case "Processing":
    case "Available":
      return value;
    default:
      return "";
  }
};

export default {
  formatDate,
  formatDateSeconds,
  formatTime,
  formatSecondsAsHours,
  formatSeconds,
  formatMilliSeconds,
  formatMilliSecondsWithMs,
  formatOutcome,
  formatClinicalSession,
  formatPercentOrNA,
  formatBooleanAsYesNo,
  formatNumberOrEmptyIfZero,
  formatLearners,
  formatPatientCase,
  formatScenarioCase,
  formatVideoStatus,
};
