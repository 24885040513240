import axios from "axios";
import moment from "moment";
import authService from "../services/auth.service";
import { DATA_TYPES, API_ERRORS } from "./constants";
import { eventEmitter, events } from "./eventEmitter";

const http = axios.create({
  baseURL: "/api/web/",
  headers: {
    common: {
      "Content-Type": "application/json",
    },
    put: {
      "Content-Type": "application/json",
    },
    post: {
      "Content-Type": "application/json",
    },
  },
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Not authorized: redirecting to login");
    } else {
      console.log("Error during axios http call:");
      console.log(error);
    }
    return Promise.reject(error);
  }
);

// Just add query params to params object which is sent
// to axios get/post requests(let axios format/escape params)
function getHttpParams(fromDate, toDate) {
  const params = {};
  if (fromDate) params.fromDate = moment(fromDate).format("YYYYMMDD");
  if (toDate) params.toDate = moment(toDate).format("YYYYMMDD");
  return params;
}

const getDataFromEndpoint = async (endpoint, params) => {
  const options = { params };

  try {
    const response = await http.get(endpoint, options);
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

const postDataToEndpoint = async (endpoint, options) => {
  try {
    const response = await http.post(endpoint, options);
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

const getNotFoundErrorMessage = (dataType) => {
  let message = "";
  switch (dataType) {
    case DATA_TYPES.LIVEBORN:
      message = "Could not load any observations for selected timeperiod";
      break;
    case DATA_TYPES.MOYO:
      message = "Could not load any moyo episodes for selected timeperiod";
      break;
    case DATA_TYPES.NEOBEAT:
      message = "Could not load any neobeat episodes for selected timeperiod";
      break;
    case DATA_TYPES.NEO_NATALIE:
      message =
        "Could not load any neonatalie sessions for selected timeperiod";
      break;
    default:
      message = "Unhandled error!";
      break;
  }
  return message;
};

export default {
  getHttpParams,
  getDataFromEndpoint,
  setAuthToken(token) {
    http.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  getBlobReferences(url, toaster, httpParams) {
    console.log("getBlobReferences()");
    console.log("url: ", url);
    return http
      .get(url, {
        params: httpParams || {},
      })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (err) => {
          let errMessage = "";
          switch (err.response.status) {
            case API_ERRORS.NOT_FOUND:
              errMessage = "Could not download data for selected timeperiod";
              break;
            case API_ERRORS.SERVER_ERROR:
              errMessage = "Something unexpected happened";
              break;
            default:
              this.errMessage = "Unhandled error while loading episodes";
              break;
          }
          toaster.error(errMessage, {
            position: "bottom-right",
          });
          return Promise.reject();
        }
      );
  },
  getBlobReferencesPost(url, toaster, body) {
    console.log("getBlobReferencesPost()");
    console.log("url: ", url);
    return http
      .post(url, body)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (err) => {
          let errMessage = "";
          switch (err.response.status) {
            case API_ERRORS.NOT_FOUND:
              errMessage = "Could not download data for selected timeperiod";
              break;
            case API_ERRORS.SERVER_ERROR:
              errMessage = "Something unexpected happened";
              break;
            default:
              this.errMessage = "Unhandled error while loading episodes";
              break;
          }
          toaster.error(errMessage, {
            position: "bottom-right",
          });
          return Promise.reject();
        }
      );
  },
  // Nicked from https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication
  downloadFile(url, contentType, httpParams, toaster, totalLength) {
    const anchor = document.createElement("a");

    return http
      .get(url, {
        responseType: "arraybuffer",
        params: httpParams || {},
      })
      .then(
        (response) => {
          // First extract filename from header
          const filename = response.headers["content-disposition"]
            .replace(/.+filename=/, "")
            .replace(/; filename.+/, "");

          // Make a blob out of the response data
          const blob = new Blob([response.data], {
            type: contentType,
            responseType: "arraybuffer",
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            const objectUrl = window.URL.createObjectURL(blob);

            anchor.href = objectUrl;
            anchor.download = filename;
            document.body.appendChild(anchor);
            const theEvent = new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            anchor.dispatchEvent(theEvent);
            window.URL.revokeObjectURL(objectUrl);
          }

          return Promise.resolve();
        },
        (err) => {
          let errMessage = "";
          switch (err.response.status) {
            case API_ERRORS.NOT_FOUND:
              errMessage = "Could not download data for selected timeperiod";
              break;
            case API_ERRORS.SERVER_ERROR:
              errMessage =
                `Request timed out for selected ${totalLength} episodes.` +
                "\n Try to download fewer episodes";
              break;
            default:
              this.errMessage = "Unhandled error while loading episodes";
              break;
          }
          toaster.error(errMessage, { position: "bottom-right" });
          return Promise.reject();
        }
      );
  },
  async downloadFileFromStream(url, httpParams, toaster) {
    if (!authService.getHttpUser().authenticatedWithTwoFactor) {
      return await authService.handle2faLogin();
    }

    return http
      .get(url, {
        responseType: "blob",
        params: httpParams || {},
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          eventEmitter.emit(
            events.downloadProgressPercentage,
            percentCompleted
          );
        },
      })
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition.split(";")[1].split("=")[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        // It's necessary to remove the anchor from body
        document.body.removeChild(link);
      })
      .catch((error) => {
        let toastMessage = "";
        switch (error.response.status) {
          case API_ERRORS.FORBIDDEN:
            toastMessage = "User not authorized to download video";
            break;

          case API_ERRORS.NOT_FOUND:
            error.response.data.text().then((message) => {
              toastMessage = message.replaceAll('"', "");
            });
            break;

          default:
            toastMessage =
              "Something went wrong. Contact your system administrator.";
            break;
        }

        toaster.error(toastMessage, { position: "bottom" });
      });
  },
  downloadFilePost(url, contentType, body, toaster, totalLength) {
    const anchor = document.createElement("a");

    return http
      .post(url, body, {
        type: contentType,
        responseType: "arraybuffer"
      })
      .then(
        (response) => {
          // First extract filename from header
          const filename = response.headers["content-disposition"]
            .replace(/.+filename=/, "")
            .replace(/; filename.+/, "");
          // Make a blob out of the response data
          const blob = new Blob([response.data], {
            type: contentType,
            responseType: "arraybuffer",
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            const objectUrl = window.URL.createObjectURL(blob);

            anchor.href = objectUrl;
            anchor.download = filename;
            document.body.appendChild(anchor);
            const theEvent = new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            anchor.dispatchEvent(theEvent);
            window.URL.revokeObjectURL(objectUrl);
          }

          return Promise.resolve();
        },
        (err) => {
          let errMessage = "";
          switch (err.response.status) {
            case API_ERRORS.NOT_FOUND:
              errMessage = "Could not download data for selected timeperiod";
              break;
            case API_ERRORS.SERVER_ERROR:
              errMessage =
                `Request timed out for selected ${totalLength} episodes.` +
                "\n Try to download fewer episodes";
              break;
            default:
              this.errMessage = "Unhandled error while loading episodes";
              break;
          }
          toaster.error(errMessage, { position: "bottom-right" });
          return Promise.reject();
        }
      );
  },
  async getDataTablePost(dataType, toaster, body) {
    const { data, error } = await postDataToEndpoint(dataType, body);

    if (error) {
      let errMessage = "";
      let showToast = true;
      switch (error.response.status) {
        case API_ERRORS.NOT_FOUND:
          errMessage = getNotFoundErrorMessage(dataType);
          showToast = false;
          break;
        default:
          errMessage = "Unhandled error!";
          break;
      }

      if (showToast) {
        toaster.error(errMessage, {
          position: "bottom",
        });
        errMessage = null;
      }
      return { data, error: errMessage };
    }
    return { data, error };
  },

  async getDataTable(
    dataType,
    toaster,
    pageNr,
    pageSize,
    fromDate,
    toDate,
    caseId
  ) {
    const httpParameters = getHttpParams(fromDate, toDate);
    httpParameters.pageNr = pageNr;
    httpParameters.pageSize = pageSize;
    if (caseId) httpParameters.caseId = caseId;

    const { data, error } = await getDataFromEndpoint(dataType, httpParameters);

    if (error) {
      let errMessage = "";
      let showToast = true;
      switch (error.response.status) {
        case API_ERRORS.NOT_FOUND:
          errMessage = getNotFoundErrorMessage(dataType);
          showToast = false;
          break;
        default:
          errMessage = "Unhandled error!";
          break;
      }

      if (showToast) {
        toaster.error(errMessage, {
          position: "bottom",
        });
        errMessage = null;
      }
      return { data, error: errMessage };
    }
    return { data, error };
  },
  getPowerBIReport(reportType) {
    return http.get(`report/${reportType}`);
  },
  putUser(updateDbUserWebDto) {
    const url = "user";
    const body = JSON.stringify(updateDbUserWebDto);

    return http.put(url, body).then(
      (value) => {
        console.log(value);
        return Promise.resolve();
      },
      (reason) => {
        console.log(reason);
        return Promise.reject(reason);
      }
    );
  },
  postUserPermissions(userPermissions) {
    const url = "userpermission";
    const body = JSON.stringify(userPermissions);
    console.log("post request body: ", body);

    return http.post(url, body).then(
      (value) => {
        console.log(value);
        return Promise.resolve();
      },
      (reason) => {
        console.log(reason);
        return Promise.reject(reason);
      }
    );
  },
  putUserPermissions(userPermissions) {
    const url = "userpermission";
    const body = JSON.stringify(userPermissions);
    console.log("put request body: ", body);

    return http.put(url, body).then(
      (value) => {
        console.log(value);
        return Promise.resolve();
      },
      (reason) => {
        return Promise.reject(reason);
      }
    );
  },
  deleteUserPermissions(userPermissions) {
    const url = "userpermission";
    const params = {
      userId: userPermissions.userId,
      siteId: userPermissions.siteId,
      permissionGroupId: userPermissions.permissionGroupId,
    };
    console.log("delete request params: ", params);

    return http.delete(url, { params }).then(
      (value) => {
        console.log(value);
        return Promise.resolve();
      },
      (reason) => {
        console.log(reason);
        return Promise.reject(reason);
      }
    );
  },
  deleteNeoNatalieLearner(learnerId) {
    const url = `neonatalie/learner/${learnerId}`;

    return http.delete(url).then(
      (value) => {
        return Promise.resolve(value);
      },
      (reason) => {
        return Promise.reject(reason);
      }
    );
  },
  postSite(site) {
    const url = "admin/site";
    const body = JSON.stringify(site);
    console.log("post request body: ", body);

    return http.post(url, body).then(
      (value) => {
        console.log(value);
        return Promise.resolve();
      },
      (reason) => {
        console.log(reason);
        return Promise.reject(reason);
      }
    );
  },
  async postSiteGroup(name) {
    const url = "site/groups";
    const body = JSON.stringify({
      name: name,
    });
    const { data } = await postDataToEndpoint(url, body);
    return data;
  },
  addSuperUsers(superUsers) {
    const url = "admin/superuser";
    const body = JSON.stringify(superUsers);
    console.log("post request body: ", body);

    return http.post(url, body).then(
      (value) => {
        console.log(value);
        return Promise.resolve(value);
      },
      (reason) => {
        console.log(reason);
        return Promise.reject(reason);
      }
    );
  },
  putSite(site) {
    const url = "admin/site";
    const body = JSON.stringify(site);
    console.log("put request body: ", body);

    return http.put(url, body).then(
      () => {
        return Promise.resolve();
      },
      (reason) => {
        return Promise.reject(reason);
      }
    );
  },
  getSiteSettings(siteId) {
    const url = `site/${siteId}`;

    return http.get(url).then(
      (data) => {
        return Promise.resolve(data);
      },
      (reason) => {
        return Promise.reject(reason);
      }
    );
  },
  putSiteSettings(site) {
    const url = "site";
    const body = JSON.stringify(site);
    console.log("put request body: ", body);

    return http.put(url, body).then(
      () => {
        return Promise.resolve();
      },
      (reason) => {
        return Promise.reject(reason);
      }
    );
  },
  downloadSessionCsv(sessionId, separator, toaster) {
    const url = `observation/excel/${sessionId}`;
    const type = "text/csv";
    return this.downloadFile(url, type, undefined, toaster, 1);
  },
  async downloadVideo(observationId, toaster) {
    const url = `observation/video/${observationId}`;
    return await this.downloadFileFromStream(url, undefined, toaster);
  },
};
